import { SVGProps } from 'react';

const LinkArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
  >
    <path
      d="M3.12533 1.25039C2.77886 1.25039 2.50013 0.970355 2.50013 0.625195C2.50013 0.280035 2.77886 0 3.12533 0H6.8765C7.22296 0 7.50169 0.280035 7.50169 0.625195V4.37637C7.50169 4.72283 7.22296 5.00156 6.8765 5.00156C6.53003 5.00156 6.2513 4.72283 6.2513 4.37637V2.13348L1.04395 7.29655C0.822523 7.56226 0.426566 7.56226 0.181697 7.29655C-0.0605658 7.07513 -0.0605658 6.67917 0.181697 6.4343L5.36821 1.25039H3.12533Z"
      fill="#6600CC"
    />
  </svg>
);

export default LinkArrow;
