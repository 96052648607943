import dynamic from 'next/dynamic';
import { useRecoilValue } from 'recoil';
import Conditional from 'components/common/Conditional';
import LongForm from 'components/common/LongForm';
import { getRelatedContentPagesUrl } from 'utils/contentPageUtils';
import { getLocalisedPrice } from 'utils/currency';
import { generateSidenavId } from 'utils/helper';
import { getPoiQuickInfo } from 'utils/parsers/poi';
import { appAtom } from 'store/atoms/app';
import { currencyListAtom } from 'store/atoms/currencyList';
import { MB_CATEGORISATION } from 'const/index';
import { strings } from 'const/strings';
import Banner from '../components/Banner';
import ListMessageBox from '../components/ListMessageBox';
import QuickInfo from '../components/QuickInfo';
import { IAboutPageProps } from '../interface';
import { PageContainer } from './styles';

const Breadcrumbs = dynamic(
  () => import(/* webpackChunkName: "Breadcrumbs" */ 'components/Breadcrumbs')
);

const AboutPage = ({
  data,
  featuredImage,
  breadcrumbs,
  taggedCity,
  primaryCity,
  isMobile,
  relatedContentPages,
  poiInfo = {},
  automatedBreadcrumbsExists,
  extractedBreadcrumbsSlice,
  extractedProductCardsSlice,
  categoryTourListData,
  parentProps,
}: IAboutPageProps) => {
  const { featured_title: featuredTitle } = data;
  const { SHOULDER_PAGE_TYPE } = MB_CATEGORISATION;
  const { language: lang } = useRecoilValue(appAtom);

  poiInfo.minPrice = getLocalisedPrice({
    price: categoryTourListData?.minPrice,
    currencyCode: categoryTourListData?.activeCurrency?.code,
    currencyList: useRecoilValue(currencyListAtom),
    lang,
  });

  const description = poiInfo?.content?.data?.description?.find?.(
    (desc: Record<string, any>) => desc.type == 'GENERAL'
  )?.description;

  const quickInfo = getPoiQuickInfo(
    {
      ...poiInfo,
      ticketsUID: data?.baseLangMicrositeData?.uid,
    },
    relatedContentPages,
    lang
  );

  const factsCtaLink = getRelatedContentPagesUrl({
    relatedContentPages,
    type: SHOULDER_PAGE_TYPE.FACTS,
    lang,
  });
  const quickInfoCtaLink = getRelatedContentPagesUrl({
    relatedContentPages,
    type: SHOULDER_PAGE_TYPE.PLAN_YOUR_VISIT,
    lang,
  });

  const PRODUCT_CARDS_LIMIT = 4;

  return (
    <>
      <Banner
        imageSrc={featuredImage}
        title={featuredTitle}
        poiInfo={{
          ALSO_KNOWN_AS: poiInfo?.content?.data?.nickname,
          FOUNDED_ON: poiInfo?.yearOpened,
          FOUNDED_BY: poiInfo?.architectedBy,
        }}
        description={description}
      />
      <PageContainer>
        <Conditional if={automatedBreadcrumbsExists}>
          <Breadcrumbs
            breadcrumbs={breadcrumbs || {}}
            taggedCity={taggedCity}
            primaryCity={primaryCity}
            isContentPage={true}
            isMobile={!!isMobile}
            isRevampedShoulderPage
          />
        </Conditional>
        <Conditional if={extractedBreadcrumbsSlice?.length}>
          <LongForm content={extractedBreadcrumbsSlice || []} />
        </Conditional>
        <Conditional if={Object.keys(quickInfo).length}>
          <QuickInfo
            id={generateSidenavId(strings.CONTENT_PAGE.QUICK_INFORMATION)}
            info={quickInfo}
            CTALink={quickInfoCtaLink}
          />
        </Conditional>
        <Conditional if={poiInfo?.content?.data?.facts?.length}>
          <ListMessageBox
            list={poiInfo?.content?.data?.facts?.slice?.(0, 3)}
            CTALink={factsCtaLink}
          />
        </Conditional>
      </PageContainer>
      <Conditional if={extractedProductCardsSlice?.length}>
        <LongForm
          content={extractedProductCardsSlice || []}
          {...parentProps}
          categoryTourListData={{
            ...categoryTourListData,
            productCardsLimit: PRODUCT_CARDS_LIMIT,
          }}
        />
      </Conditional>
    </>
  );
};

export default AboutPage;
