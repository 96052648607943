const LinkBoxWithoutArrow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M4.16486 6.45781C4.16486 5.65209 4.81792 4.99902 5.62365 4.99902H8.54122C8.88769 4.99902 9.16642 5.27906 9.16642 5.62422C9.16642 5.97068 8.88769 6.24941 8.54122 6.24941H5.62365C5.5085 6.24941 5.41525 6.34319 5.41525 6.45781V14.377C5.41525 14.4916 5.5085 14.5854 5.62365 14.5854H13.5428C13.6574 14.5854 13.7512 14.4916 13.7512 14.377V11.4594C13.7512 11.1129 14.0299 10.8342 14.3764 10.8342C14.7228 10.8342 15.0016 11.1129 15.0016 11.4594V14.377C15.0016 15.1819 14.3477 15.8357 13.5428 15.8357H5.62365C4.81792 15.8357 4.16486 15.1819 4.16486 14.377V6.45781Z"
      fill="#6600CC"
    />
  </svg>
);

export default LinkBoxWithoutArrow;
