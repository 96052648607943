const ListStar = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
  >
    <g clipPath="url(#clip0_3213_7400)">
      <path
        d="M6.125 1.27527L7.67 4.40527L11.125 4.91027L8.625 7.34527L9.215 10.7853L6.125 9.16027L3.035 10.7853L3.625 7.34527L1.125 4.91027L4.58 4.40527L6.125 1.27527Z"
        stroke="#444444"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3213_7400">
        <rect
          width="12"
          height="12"
          fill="white"
          transform="translate(0 0.000244141)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default ListStar;
